import React from "react";

const Home = () => {
  return (
    <React.StrictMode>
      <div className="main-page-content">
        <div id="home">
          <div id="particles-js"></div>
          <div className="home-content-main">
            <div className="table-cell">
              <div className="container">
                <div className="row home-row">
                  <div className="col-md-12 col-sm-12">
                    <div className="home-text wow fadeIn text-center">
                      <h1 className="cd-headline clip is-full-width">
                        <span
                          className="cd-words-wrapper"
                          style={{ width: "266px", overflow: "hidden", maxWidth: "90vw" }}
                        >
                          <b className="is-hidden small-font">Crafting Solutions..</b>
                          <b className="is-hidden small-font">IT Consultancy..</b>
                          <b className="is-visible small-font">Innovating Ideas..</b>
                        </span>
                      </h1>
                      <div className="about-social-icon text-center">
                        <ul className="about-social">
                          <li className="wow fadeIn" data-wow-delay=".4s">
                            <a
                              href="https://www.linkedin.com/company/87078309"
                              target="newtab"
                            >
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="wow fadeIn" data-wow-delay=".2s">
                            <a
                              href="https://github.com/ranjithbantu"
                              target="newtab"
                            >
                              <i
                                className="fa fa-github"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li className="wow fadeIn" data-wow-delay=".6s">
                            <a
                              href="mailto:contactus@techbantu.us"
                              target="newtab"
                            >
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="parallax" data-velocity="-.1"></div>
          <div className="parallax" data-velocity="-.5" data-fit="525"></div>
        </div>

        {/* <!-- ============================================== SERVICE ===================================================== --> */}

        <div id="service">
          <div className="service-content">
            <div className="service-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Our Services</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        Our full-stack team provides a stunning solution in Agile ways.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/design-development.jpg"
                          alt="design-development"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-edit service-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Web Development</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          We provide best platforms for your web presence by designing and building your websites and marketing portfolios
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/mobile-friendly.jpg"
                          alt="mobile-friendly"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-desktop exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Mobile & Desktop Apps</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Using state of the art technologies our team tailors
                          perfect iOS and Android applications, as per your specifications.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/e-commarce.jpg"
                          alt="e-commarce"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-cart-plus exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>eCommerce Solution</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          We can provide integration platforms, payment gateways,
                          hospitality and retail solutions to boost your sales.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/project.png"
                          className="custom-image"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-check-square-o service-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Agile Project Management</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Quality and delivery tracking.
                          Product release versioning, planning, and management to meet your deadlines.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/analytics.jpg"
                          alt="analytics"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-tachometer service-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Search Engine Optimization</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Get insights about your visitors and make smarter business decisions. Improve your page performance, SEO and experience.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/data.png"
                          className="custom-image"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-bar-chart exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Data Management & Analytics</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Looking to improve your page performance, SEO, or user
                          experience? Request a free site audit.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/content-management.jpg"
                          alt="content-management"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-cogs exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Automation</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Designing and testing automation equipment and processes. Programming new automated components.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          src="assets/img/service/discuss.png"
                          className="custom-image"
                        />
                      </div>
                      <div className="service-bottom">
                        <i
                          className="fa fa-building-o exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Consultancy</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Providing consultancy in agile adoption of software, project rescue, UI/UX design, analysing data and clear communication.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-6">
                    <div className="service-details" data-wow-delay=".1s">
                      <div className="service-head">
                        <img
                          className="custom-image "
                          src="assets/img/service/QA.png"
                        />
                      </div>
                      <div className="service-bottom">
                        <div className="custom-icon">
                          <img
                            src="./assets/img/test-svgrepo-com.svg"
                            aria-hidden="true"
                            width="30"
                          />
                        </div>
                        <h3>Testing & QA</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Our processes ensure thorough & rigorous testing of each functionality before deliveries.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ================================ ABOUT =============================== --> */}

        <div id="about">
          <div className="about-content">
            <div className="love-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>How we do</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        We believe in our core processes, empowering us to deliver more consistently and efficiently. We are always looking forward to improve those processes, unleashing the true power of agility in our team.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".1s">
                      <img
                        src="./assets/img/think.png"
                        className="love-icon-custom"
                      />
                      <h3>Brainstrom</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Your idea & we will plan it together</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".3s">
                      <img
                        src="./assets/img/design.png"
                        className="love-icon-custom"
                      />
                      <h3>Design</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Prototyping through collaboration</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".2s">
                      <img
                        src="./assets/img/unlock.png"
                        className="love-icon-custom"
                      />
                      <h3>Unlocking Agility</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Develop & deliver software in sprints</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <img
                        src="./assets/img/review.png"
                        className="love-icon-custom"
                      />
                      <h3>Review</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Constant improvement through feedbacks</p>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <img
                        src="./assets/img/delivery.png"
                        className="love-icon-custom"
                      />
                      <h3>Delivery</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>Happy customers with quality products</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* <!-- ================================ Skill =============================== --> */}

        <div id="expertise">
          <div className="about-content">
            <div className="love-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeInOut">
                      <h3>Our Expertise</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        Our solutions are powered by wide range of latest tech stacks.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row wow fadeIn">
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".1s">
                      <img
                        src="./assets/img/logos/react.png"
                        className="stacks-custom"
                      />
                      <h3>ReactJs</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details fadeInLeft" data-wow-delay=".3s">
                      <img
                        src="./assets/img/logos/nodejs.png"
                        className="stacks-custom"
                      />
                      <h3>NodeJs</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".2s">
                      <img
                        src="./assets/img/logos/angular.png"
                        className="stacks-custom"
                      />
                      <h3>Angular</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <img
                        src="./assets/img/logos/django.png"
                        className="stacks-custom"
                      />
                      <h3>Django</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <img
                        src="./assets/img/logos/devops.png"
                        className="stacks-custom"
                      />
                      <h3>DevOps</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <img
                        src="./assets/img/logos/spring.png"
                        className="stacks-custom"
                      />
                      <h3>Java - SpringBoot</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <img
                        src="./assets/img/logos/ror.png"
                        className="stacks-custom"
                      />
                      <h3>ROR</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="love-details" data-wow-delay=".4s">
                      <img
                        src="./assets/img/logos/net.png"
                        className="stacks-custom"
                      />
                      <h3>.Net Core</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="testimonial">
          <div className="testimonial-content">
            <div className="testimonial-grid">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Testimonials</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        People we have worked with and have said some nice things!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testimonial-details">
                <section id="carousel">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8 col-md-offset-2">
                        <div className="quote">
                          <i className="fa fa-quote-left fa-4x"></i>
                        </div>
                        <div
                          className="carousel slide"
                          id="fade-quote-carousel"
                          data-ride="carousel"
                          data-interval="3000"
                        >
                          <ol className="carousel-indicators">
                            <li
                              data-target="#fade-quote-carousel"
                              data-slide-to="0"
                              className="active"
                            ></li>
                            <li
                              data-target="#fade-quote-carousel"
                              data-slide-to="1"
                            ></li>
                            <li
                              data-target="#fade-quote-carousel"
                              data-slide-to="2"
                            ></li>
                          </ol>
                          <div className="carousel-inner">
                            <div className="active item">
                              <blockquote>
                                <p>
                                  “Honestly, it was the best decision working with you guys. Made things so much easier. Would love to work with your company again”
                                  <br />
                                  <span>Chris Roy</span>
                                </p>
                              </blockquote>
                            </div>
                            <div className="item">
                              <blockquote>
                                <p>
                                  “Top notch quality delivered. Really appreciate the hard work.”
                                  <br />
                                  <span>Jason Robert</span>
                                </p>
                              </blockquote>
                            </div>
                            <div className="item">
                              <blockquote>
                                <p>
                                  “Highly responsive team. Every part of my product was delivered with extreme efficiency
                                  and optimization. I can't ask for more .”
                                  <br />
                                  <span>Martin Lassen</span>
                                </p>
                              </blockquote>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div id="contact">
          <div className="contact-content">
            <div className="text-grid">
              <div className="text-grid-main">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="main-title text-center wow fadeIn">
                        <h3>Contact us</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          Let's get your business problems solved. Get in touch. Don't worry, the coffee ☕ is on us.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-grid">
              <div className="contact-form-details wow fadeIn">
                <div className="container">
                  <div className="row contact-info-row text-center wow fadeIn">
                    <div className="col-md-3 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".2s">
                        <i
                          className="fa fa-map-marker contact-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Address</h3>
                        <p>San Diego, California, USA</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".4s">
                        <a
                          href="mailto:contactus@techbantu.us"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "17px",
                          }}
                        >
                          <i
                            className="fa fa-envelope contact-icon"
                            aria-hidden="true"
                          ></i>
                          <h3>Email</h3>
                          <p>
                            contactus@techbantu.us
                          </p>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".6s">
                        <a
                          href="tel:8372876775"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "17px",
                          }}
                        >
                          <i
                            className="fa fa-mobile contact-icon"
                            aria-hidden="true"
                          ></i>
                          <h3>Phone</h3>
                          <p>
                            +1 619-277-8065 </p>
                        </a>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".6s">
                        <a href="https://www.linkedin.com/company/87078309" style={{ textDecoration: "none" }}>
                          <i
                            className="fa fa-television contact-icon"
                            aria-hidden="true"
                          ></i>
                          <h3>Linkedin</h3>
                          <p>Linkedin</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default Home;
